import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import PrintIcon from '@mui/icons-material/Print';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MediationIcon from '@mui/icons-material/Mediation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReceiptIcon from '@mui/icons-material/Receipt';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[200],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[300]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.greenAccent[800]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.greenAccent[800]} !important`,
        },
        "& .pro-sidebar": {
          height: '100vh',
          overflow: 'hidden',
          zIndex: 1000, // Setting the z-index
          boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        },
      }}
    >
      <ProSidebar   
       rootStyles={{
          position: '-webkit-sticky',
          position: 'sticky',
          top: 0
        
    }} collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[200]}>
                  KMS INVENTORY
                </Typography>

                
                
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
{/* 
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Ed Roh
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {isCollapsed ? <hr width="50%" color="green" /> :   <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
               INVENTORY
            </Typography>}

          
            <Item
              title="Laptops"
              to="/laptops"
              icon={<LaptopMacIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Kiosk"
              to="/kiosk"
              icon={<ImportantDevicesIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Printers"
              to="/printers"
              icon={<PrintIcon />}
              selected={selected}
              setSelected={setSelected}
            />

              <Item
              title="Scanners"
              to="/scanners"
              icon={<QrCodeScannerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Toner"
              to="/toner"
              icon={<ColorLensIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Extra"
              to="/extra"
              icon={<EngineeringIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {isCollapsed ?     <hr width="50%" color="purple" /> : <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              SHOWS 
           </Typography>}

            


            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Show Management"
              to="/shows"
              icon={<MediationIcon />}
              selected={selected}
              setSelected={setSelected}
            />

{isCollapsed ?     <hr width="50%" color="purple" /> : <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              INVOICES 
           </Typography>}

           <Item
              title="Invoice Que"
              to="/que"
              icon={<QueryBuilderIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           <Item
              title="Library"
              to="/library"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
            />

          

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            


          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
